.privacy-policy {
  font-family: $bold-text;
  margin-top: 90px;

  @media screen and (min-width: 800px) {
    margin-top: 150px;
  }

  .privacy-policy-title{
    margin: 0 auto;
    text-align: center;
    padding: 0px;
    h1{
      font-size: 20px;
      font-weight: 600;
      padding: 0px;
      @media screen and (min-width: 800px) {
        font-size: 24px;
      }
    };
  }

  .privacy-policy-subtitle {
    margin: 0 auto;
    text-align: center;

    padding: 0px;


    h1{
      font-size: 20px;
      font-weight: 600;
      line-height: 0px;
      padding: 0px;

      @media screen and (min-width: 800px) {
        font-size: 24px;
      }
    };
  }

  .privacy-policy-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    @media screen and (min-width: 800px) {
      margin: 0 auto;
      padding: 0px 60px;
      width: 1000px;
    }
    p{
      //line-height: 28px;
      margin: 0px;
      padding: 0px;
      padding-top: 10px;

      @media screen and (min-width: 800px) {
        padding-top: 20px;
        font-size: 22px;
      }
      a{
        line-height: 0px;
        @media screen and (min-width: 800px) {
          font-size: 22px;
        }
      }

    }
  }

  .privacy-policy-list{

    font-weight: normal;
    font-size: 20px;

    @media screen and (min-width: 800px) {
      font-size: 22px;

    }

    ol{

      padding-top: 10px;

      li{
        padding-bottom: 10px;
      }

      a{
        padding: 0px;
        line-height: 0px;
        @media screen and (min-width: 800px) {
          font-size: 22px;
        }

      }
    }
    ol li {
      display:block;
    }
    ol > li:first-child {
      counter-reset: item;
    }
    ol > li {
      counter-increment: item; position: relative;
    }
    ol > li:before {
      content:counters(item, ".") ". ";
      position: absolute;
      margin-right: 100%;
      right: 10px;
    }

  }
}
