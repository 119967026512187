// Import basic styles.
@import "~normalize.css";
@import 'variables';
@import 'typography';
@import 'buttons';
@import 'shadow';
@import 'footer';
@import 'header';
@import 'pages/privacy-policy';

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  //overflow-y: auto;
  z-index: 50000;
  background-color: rgba(36, 36, 36, 0.36);;
  transform: translateX(100%);
  transition: 250ms ease-in-out;

  /* Slide header in */
  &.open {
    visibility: visible;
    transform: translateX(0);
  }

  /* Make sure mobile nav hides on desktop */
  @media(min-width: $screen-xlg) {
    display: none;
  }

  &__content {
    height: 95%;
    width: 92%;
    background-color: $white;
    padding: 0 26px;
    margin: 15px 0 0 15px;

    /* Restyle close button */
    .close {
      position: absolute;
      top: 30px;
      right: 40px;
      float: none;
      font-size:28px;
      opacity: 1;
      text-align: center;
      font-weight:normal;
      text-shadow: none;

      @media(min-width: $screen-sm) {
        right: 80px;
      }
      // &:hover, &:focus {
      //   background: darken($gray-dark, 5%);
      // }
    }

    .btn-holder {
      display:none;

      .showed {
        display:block;
        margin:26px 26px 15px;
      }
    }

    /* Give auth room */
    .mobile-nav-auth {
      display:none;
      margin-top: 15px;
      margin-bottom: 26px;
      font-family: $bold-text;

      a.btn {
        margin-left:26px;

        &.eda-header-button-primary {
          border-radius: 20px;
          border-style: solid;
          border-width: 1px;
          padding-left: 24px;
          padding-right: 24px;
          height: 42px;
          padding-top: 8px;

          color: $white;
          background-color: $pink-EDA;
          border: 2px solid $pink-EDA;

          &:hover, &:focus {
            color: $pink-EDA;
            background-color: $white;
            border: 2px solid $pink-EDA;
          }
        }

        &.eda-header-button-primary-inverse {
          border-radius: 20px;
          border-style: solid;
          border-width: 1px;
          padding-left: 24px;
          padding-right: 24px;
          height: 42px;
          padding-top: 8px;

          color: $pink-EDA;
          background-color: $white;
          border: 2px solid $pink-EDA;

          &:hover, &:focus {
            color: $white;
            background-color: $pink-EDA;
            border: 2px solid $pink-EDA;
          }
        }
      }
    }

    .account-logged-in {
      display:none;
    }

    /* Headers */
    .mobile-header {
      font-size: 16px;
      margin-left: 26px;
      /* margin-bottom: 18px; */
      /* color: lighten($black, 53.5%); */
    }

    /* Unstyle all lists */
    ul {
      padding-left:0;
      list-style: none;

      li {
        padding: 20px 0;
      }

      /* Media stylings within list */

      &.loggedin-list {
        /*
        border-bottom: 1px solid #888888;
        margin-bottom: 26px;
        */
        padding-bottom: 26px;

        a {
          color: $white;
          text-decoration: none;
          height: 100%;
          display: inline-block;

          &:hover, &:focus {
            background: darken($gray-dark, 5%);
          }
        }

        .media {
          cursor: pointer;
          padding: .8rem 0;
        }

        .media-left {
          vertical-align: middle;
        }

        i.media-object, icon.media-object {
          /* font-size: 28px; */
          font-size: 10px;
        }

        .media-body {
          /* top: 3px; */
          position: relative;
          padding-left: 6px;
        }
      }

      /* Media stylings within list */

      &.mobile-service-list {
        border-top: 1px solid #BBC7CE;

        .media {
          /* padding: .8rem 0; */
          /* padding: .7rem 0; */
          /* cursor: pointer; */
          padding: 0;
        }

        a.link-header-mobile {
          color: unset;
          height: 100%;
          width: 100%;
          display: inline-block;
          padding: .7rem 0;
          cursor: pointer;

          &:hover, &:focus {
            background-color: darken($gray-dark, 5%);
          }
        }

        .media-left {
          /* vertical-align: middle; */
          display: inline-block;
          vertical-align: top;
          padding-top: 5px;
          padding-right: 7px;
        }

        i.media-object, icon.media-object {
          /* font-size: 28px; */
          font-size: 10px;
        }

        .media-body {
          /* top: 3px; */
          position: relative;
          padding-left: 6px;
          display: inline-block;
          width: unset;
        }
      }

      &.brochure-list {
        border-top: 1px solid #888888;

        li {
          font-size: 18px;
          font-family: $bold-text;
          text-transform: uppercase;
          margin-top:26px;
        }

        a {
          // color: $white;
          // font-family: $regular-text;
          text-decoration: none;

          &:hover, &:focus {
            font-family: $bold-text;
            text-decoration: underline;
          }
        }
      }
    }

    &.signed-out {
      .mobile-nav-auth {
        display:block;
      }

      .account-logged-in {
        display:none;
      }
    }

    &.signed-in {
      .account-logged-in {
        display: block;
        margin-top: 20px;
      }
    }
  }
}

.navbar-new {
  position: fixed;
  top: 15px;
  left: 15px;
  right: 15px;
  border: 0;
  border-radius: 5px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.06), 0px 8px 25px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.035), 0px 1px 5.57042px rgba(0, 0, 0, 0.0243888);

  .navbar-header-flag-container {
    display: flex;
    width: 37px;
    height: 22px;
    font-size: 14px;
  }

  .navbar-header-flag {
    margin-left: 0.5rem;
    margin-top: 0.15rem;
    height: 20px;

    @media (max-width: $screen-sm) {
      margin-top: -0.1rem;
    }
  }

  .navbar-header-new {
    height: 94px;

    @media (max-width: $screen-xlg) {
      height: 59px;
    }

    &:before, &:after {
      display: block;
      content: none;
    }

    &__view-careers {
      text-align: right;
      &__button {
        text-decoration: unset;
        padding: 10px 30px;
        min-width: 102px;
      }
    }

    &__desktop {
      height: 100%;
      background: rgba(255, 255, 255, 0.95);
      display: grid;
      grid-template-columns: 0fr 3fr 1fr;
      align-items: center;
      padding: 0 40px;

      @media (max-width: $screen-xlg) {
        display: none;
      }
    }

    &__mobile {
      display: none;
      opacity: 1;
      background-color: white;
      height: 59px;

      .icon-ic-burger:before {
        content: "\e90e";
        font-family: $font-icons-rebrand;
      }

      .mobile-menu-icon {
        font-size: 30px;
      }

      @media (max-width: $screen-xlg) {
        display: grid;
        grid-template-columns: 0fr 3fr 0fr;
        align-items: center;
        padding-left: 20px;
        padding-right: 35px;
      }
    }

    &__logo {
      justify-self: center;

      &__img {
        width: 150px;
      }

      @media (max-width: $screen-xlg) {
        padding-top: 5px;

        &__img {
          width: 85px;
        }
      }
    }

    &__links-block {
      display: flex;
      justify-self: right;

      &__item {
        margin-right: 25px;
      }
    }

    &__link {
      color: $color-font;
      cursor: pointer;

      @media (max-width: $screen-laptop) {
        font-size: 16px;
      }

      &:focus, &:hover {
        color: $color-font;
        text-decoration: none;
      }
    }

    &__auth-holder {
      display: flex;
      align-items: center;
      justify-self: flex-end;
    }
  }
}

.new-design-homepage {
  font-size: 16px;
  padding-top: 0;
  overflow-x: hidden;
  margin: 0;
  color: #242424;
  font-style: normal;

  &__background-primary {
    background-color: $background-primary;

    &.our-uk-partnership {
      width: 740px;
      height: 294px;
      top: 240px;
      /* left: 305px; */

      @media (max-width: $screen-sm - 1) {
        left: 0;
        width: 266px;
        height: 125px;
        top: 0;
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        width: 346px;
        height: 137px;
      }
    }

    &.patient-outside-background {
      width: 100%;
      height: 257px;
      position: relative;
      top: -220px;
      z-index: -1;

      display: none;

      @media (min-width: ($screen-sm)) {
        display: block;
      }

      &.mobile {
        display: none;

        @media (max-width: $screen-sm - 1) {
          display: block;
          top: unset;
          bottom: 0;
          position: absolute;
          width: 326px;
          height: 155px;
          margin-bottom: -30px;
        }
      }

      @media (max-width: $screen-sm - 1) {
        top: 32vh;
        height: 125px;
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        height: 228px;
        top: -196px;
      }
    }
  }


  .new-design-our-story {
    background-color: #E9F2E6;
    min-height: 796px;
    display: flex;

    @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
      min-height: 700px;
    }

    @media (max-width: $screen-sm - 1) {
      flex-direction: column;
      overflow: hidden;
    }

    &__mobile-container {
      position: relative;

      &__icon {
        color: white;
        font-size: 1.5rem;
        positioN: absolute;
        top: -11vw;
        left: 48vw;
      }
    }

    &__mobile-text {
      display: none;

      @media (max-width: $screen-sm - 1) {
        line-height: 15vh;
        display: block;
        position: absolute;
        height: 22vh;
        top: -26vh;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 35, 18, 0) 0%, #000C06 100%);
        text-align: center;
        font-family: "Atkinson Hyperlegible", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-size: 36px;
        color: $white;
      }
    }

    &__img-container {
      position: absolute;

      @media (max-width: $screen-sm - 1) {
        position: unset;
      }
    }

    &__first-col {
      min-width: calc(120px + 691px);

      @media (max-width: $screen-laptop) {
        min-width: calc(120px + 493px);
      }

      @media (max-width: $screen-sm - 1) {
        min-width: unset;
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        min-width: 376px;
      }
    }

    &__img {
      position: absolute;
      left: 120px;
      width: 691px;
      /* height: 866px; */

      @media (max-width: $screen-laptop) {
        display: none;
      }


      &.mobile {
        display: none;

        @media (max-width: $screen-laptop) {
          display: block;
        }
      }

      @media (max-width: $screen-laptop) {
        width: 493px;
        /* height: 841px; */
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        width: 376px;
        /* height: 689px; */
        left: 0;
      }

      @media (max-width: $screen-sm - 1) {
        left: 0;
        width: 100%;
        position: unset;
      }
    }

    &__second-col {
      padding-top: 263px;
      padding-right: 120px;
      margin-left: 157px;

      @media (max-width: $screen-laptop) {
        padding-top: 184px;
        padding-right: 60px;
      }

      @media (max-width: $screen-sm - 1) {
        padding-top: 0;
        text-align: center;
        margin-left: 0;
        padding-left: 30px;
        padding-right: 30px;

        &__title {
          display: none;
        }

        &__subtitle {
          padding-top: 0;
          padding-top: 90px;
          padding-bottom: 90px;
          text-align: left;
          line-height: 150%;
          letter-spacing: 0.01em;
        }
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        margin-left: 45px;
      }
    }
  }


  .new-design-our-mission {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 $space-xlg 210px;

    @media (max-width: $screen-laptop) {
      padding: 0 $space-m $space-xlg;
    }

    @media (max-width: $screen-sm - 1) {
      margin-top: 90px;
      display: block;
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
      padding-left: 25px;
      padding-right: 25px;
    }

    &__first-col {
      max-width: 400px;
      padding-top: 300px;

      @media (max-width: $screen-laptop) {
        max-width: 280px;
        padding-top: 0;
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        padding-top: 160px;
      }

      &__title {
        margin-top: 0;
        margin-bottom: $space-s;
      }

      &__subtitle {
        margin-bottom: $space-s;
      }

      &__description, &__description a {
        font-size: 12px;
        line-height: 150%;
      }
    }

    &__second-col {
      position: relative;

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        margin-top: -120px;
      }

      &__background {
        position: relative;
        width: 590px;
        height: 423px;
        margin-top: 197px;
        background-color: $background-primary;

        @media (max-width: $screen-laptop) {
          width: 679px;
          height: 336px;
        }

        @media (max-width: $screen-sm - 1) {
          width: 100%;
          margin-top: 32px;
        }

        @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
          width: 264.2px;
          height: 189.85px;
        }
      }

      &__img {
        position: absolute;
        top: 0;
        width: 301px;
        /* height: 569px; */

        &.staff-smile {
          right: 500px;
          margin-top: 200px;

          @media (max-width: $screen-sm - 1) {
            display: none;
          }

          &.mobile {
            display: none;

            @media (max-width: $screen-sm - 1) {
              display: block;
              right: 157px;
              margin-top: 10%;
            }
          }

          @media (max-width: $screen-laptop) {
            width: 280px;
            /* height: 484px; */
          }

          @media (max-width: $screen-sm - 1) {
            width: 150px;
            /* height: 286px; */
          }

          @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
            width: 147px;
            /* height: 255px; */
            right: 205px;
            top: 128px;
            margin-top: 20px;
          }
        }

        &.smiling-family {
          margin-top: 533px;
          right: 132px;
          width: 353px;
          /* height: 353px; */

          @media (max-width: $screen-sm - 1) {
            display: none;
          }

          &.mobile {
            display: none;

            @media (max-width: $screen-sm - 1) {
              display: block;
              width: 150px;
              /* height: 112px; */
              margin-top: 35px;
              right: 0;
            }
          }

          @media (max-width: $screen-laptop) {
            width: 300px;
            /* height: 300px; */
            margin-top: 448px;
          }

          @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
            width: 158px;
            /* height: 158px; */
            right: 33px;
            margin-top: 296px;
          }
        }

        &.elderly-couple {
          width: 486px;
          /* height: 570px; */
          right: 0;
          top: -10%;

          @media (max-width: $screen-sm - 1) {
            display: none;
          }

          &.mobile {
            display: none;

            @media (max-width: $screen-sm - 1) {
              display: block;
              width: 150px;
              top: 0;
              margin-top: 158px;
            }
          }

          @media (max-width: $screen-laptop) {
            width: 413px;
            /* height: 484px; */
          }

          @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
            width: 217px;
            /* height: 255px; */
            top: 8%;
            right:-25px;
          }
        }
      }
    }
  }

  .our-mission-driven-team {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 77px 0 61px;
    padding-bottom: 300px;

    @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
      padding-bottom: 100px;
      padding-top: 20px;
    }

    @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
      padding-bottom: 0px;
    }

    @media (max-width: $screen-sm - 1) {
      flex-direction: column;
      margin-top: 122px;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 0;
    }

    &__img-block {
      width: 518px;
      position: absolute;
      left: 55px;
      display: none;

      @media (min-width: ($screen-sm - 1)) {
        display: block;
      }

      @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
        width: 413px;
      }

      @media (min-width: ($screen-sm + 1)) and (max-width: $screen-xlg) {
        width: 285px;
      }

      &.mobile {
        display: none;
        position: unset;

        @media (max-width: $screen-sm - 1) {
          display: block;
          width: 100%;
          position: relative;
          left: 0;
          padding-top: 90px;
        }
      }
    }

    &__content {
      padding-left: 958px;
      padding-right: 260px;
      /*
      max-width: 692px;
      width: 100%;
      height: 439px;
      margin-left: 275px;
      margin-right: 260px;
      */

      &__mobile-content {
        display: none;

        @media (max-width: $screen-xlg) {
          font-size: 14px;
          line-height: 150%;
          margin-top: 30px;
          display: block;
        }
      }

      @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
        padding-left: 600px;
        padding-right: 60px;
        /*
        margin-right: 144px;
        max-width: 430px;
        */
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        padding-left: 440px;
        padding-right: 30px;
        /*
        margin-right: 30px;
        margin-left: 100px;
        */
      }

      @media (max-width: $screen-sm - 1) {
        padding-left: unset;
        padding-right: unset;
        /* margin-left: 10px; */

        &__subtitle {
          line-height: 150%;
          letter-spacing: 0.01em;
        }
      }

      &__button-container {
        margin-top: 50px;
        display: flex;
        min-width: 430px;

        @media (min-width: ($screen-sm + 1)) and (max-width: $screen-xlg) {
          flex-direction: column;
          min-width: unset;
          max-width: 280px;
        }

        @media (max-width: $screen-sm) {
          flex-direction: column;
          padding-left: 30px;
          min-width: unset;
          max-width: 280px;
        }

        a {
          margin-right: 10px;

          @media (min-width: ($screen-sm + 1)) and (max-width: $screen-xlg) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }

        &__see-reviews {
          border: none;
          text-decoration: none;
          text-align: center;

          &:hover {
            background: none;
            color: $color-primary;
          }

          @media (max-width: $screen-xlg) {
            display: none;
          }

          @media (min-width: ($screen-sm + 1)) and (max-width: $screen-xlg) {
            /* display: none; */
          }
        }

        &__view-careers {
          text-align: center;
          text-decoration: none;
        }
      }

      &__title {
        margin-top: 180px;
        margin-bottom: 30px;

        @media (max-width: $screen-sm) {
          margin-top: 0;
        }

        @media (min-width: ($screen-sm + 1)) and (max-width: $screen-xlg) {
          margin-top: 20px;
        }
      }
    }

    &__img {
      width: 100%;
      /* width: 518px; */

      @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
        width: 413px;
      }

      @media (min-width: ($screen-sm + 1)) and (max-width: $screen-xlg) {
        width: 285px;
      }

      @media (max-width: $screen-sm) {
        width: 256px;
        margin-left: 30px;
      }
    }
  }

  .our-mission-driven-team-background {
    width: 629px;
    height: 417px;
    background-color: $background-primary;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;

    display: none;

    @media (min-width: ($screen-sm )) {
      display: block;
    }

    &.mobile {
      display: none;

      @media (max-width: $screen-sm - 1) {
        display: block;
        height: 320px;
        width: 320px;
        margin-bottom: -30px;
      }
    }

    @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
      width: 530px;
    }

    @media (min-width: $screen-sm) and (max-width: $screen-xlg) {
      width: 346px;
      height: 234px;
      margin-left: 30px;
      top: 360px;
    }

    @media (max-width: $screen-sm - 1) {
      width: 100%;
      margin-top: 185%;
      height: 203px;
    }
  }

  .our-uk-partnership {
    position: relative;
    display: flex;
    margin: 77px 0 61px;

    &.new-design-homepage__background-primary {
      position: absolute;
      right: 140px;

      @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
        right: 0;
        width: 600px;
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        right: 0;
      }

      @media (max-width: $screen-sm - 1) {
        z-index: -1;
        top: unset;
        bottom: 0;
        height: 180px;
        margin-bottom: -30px;
      }
    }

    @media (max-width: $screen-sm - 1) {
      flex-direction: column;
      padding-left: 30px;
      padding-right: 30px;
    }

    &__content {
      margin-left: 90px;
      max-width: 550px;
      width: 100%;
      padding-top: 100px;

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        width: 50%;
      }

      &__logo {
        /* margin-top: 180px; */
        width: 89px;
        height: 47px;

        @media (max-width: $screen-sm - 1) {
          display: none;
        }

        &.mobile {
          display: none;

          @media (max-width: $screen-sm - 1) {
            display: block;
          }
        }

        @media (max-width: $screen-sm - 1) {
          margin-top: 15px;
        }
      }

      @media (max-width: $screen-laptop) {
        margin-left: 90px;
      }

      @media (max-width: $screen-sm - 1) {
        margin-left: 0;
        padding-top: 60px;
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        margin-left: 30px;
      }
    }

    &__img-block {
      /* margin-top: 6rem; */

      @media (max-width: $screen-sm - 1) {
        margin-top: 4rem;
        position: relative;
      }

      &__img {
        position: absolute;
        /* right: 270px; */
        width: 620px;
        height: 645px;
        z-index: 999;
        right: 200px;

        @media (max-width: $screen-sm - 1) {
          display: none;
        }

        &.mobile {
          display: none;

          @media (max-width: $screen-sm - 1) {
            display: block;
            position: unset;
            margin-left: 30px;
          }
        }

        @media (max-width: $screen-laptop) {
          width: 522px;
          height: 544px;
          right: 38px;
        }

        @media (max-width: $screen-sm - 1) {
          width: 264px;
          height: 275px;
        }

        @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
          width: 289px;
          height: 301px;
          right: 30px;
          top: 120px;
        }
      }
    }
  }

  .patient-outside {
    position: relative;
    display: flex;
    margin-top: 320px;

    @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
      margin-top: 320px;
    }

    @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
      margin-top: 0px;
    }

    @media (max-width: $screen-sm - 1) {
      margin-top: 0;
      flex-direction: column;
    }

    &__img-block {
      min-width: calc(154px + 638px);

      @media (max-width: $screen-laptop) {
        min-width: calc(89px + 552px);
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        min-width: 38%;
      }

      &__img {
        position: absolute;
        width: 638px;
        /* height: 531px; */
        left: 154px;

        @media (max-width: $screen-sm - 1) {
          display: none;
        }

        &.mobile {
          display: none;

          @media (max-width: $screen-sm - 1) {
            display: block;
            top: 0;
            margin-top: 60px;
            position: unset;
            padding-left: 30px;
          }
        }

        @media (max-width: $screen-laptop) {
          width: 552px;
          /* height: 459px; */
          left: 62px;
        }

        @media (max-width: $screen-sm - 1) {
          width: 264.35px;
          /* height: 275.49px; */
        }

        @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
          width: 284px;
          /* height: 236px; */
          top: 96px;
          left: 43px;
        }
      }
    }

    &__content {
      margin-left: 175px;
      width: 100%;
      max-width: 550px;

      @media (max-width: $screen-laptop) {
        margin-left: 30px;
      }

      @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
        margin-left: 80px;
      }

      @media (max-width: $screen-sm - 1) {
        padding-top: 30px;
      }

      &__subtitle {
        margin-top: 180px;

        @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
          margin-top: 140px;
          padding-right: 30px;
        }

        @media (min-width: ($screen-sm)) and (max-width: $screen-xlg) {
          margin-top: 140px;
          padding-right: 30px;
        }

        @media (max-width: $screen-sm - 1) {
          margin-top: 0;
          padding-right: 30px;
        }
      }
    }
  }

  .new-design-team {
    margin: -100px 120px 0;

    @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
      margin: -100px 60px 0;
    }

    @media (min-width: ($screen-sm + 1)) and (max-width: $screen-xlg) {
      margin: -100px 0 0;
    }

    @media (max-width: $screen-sm) {
      margin: 60px 0 0;
    }

    &__img {
      width: 100%;
      /* height: 459px; */

      @media (max-width: $screen-sm) {
        display: none;
      }

      &.mobile {
        display: none;

        @media (max-width: $screen-sm) {
          display: block;
        }
      }

      @media (max-width: $screen-sm) {
        /* height: 129px; */
        margin-top: 2rem;
      }

      @media (min-width: ($screen-sm + 1)) and (max-width: $screen-xlg) {
        /* width: 769px; */
        /* height: 267px; */
      }
    }
  }

  .new-design-timeline {
    margin-top: 120px;

    @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
      margin-top: 112px;
    }

    @media (min-width: ($screen-sm + 1)) and (max-width: $screen-xlg) {
      margin-top: 90px;
    }

    @media (max-width: $screen-sm) {
      margin-top: 90px;
    }

    &__title {
      margin-left: 115px;
      margin-top: 0;

      @media (max-width: $screen-sm) {
        margin-left: 0;
        text-align: left;
        padding-left: 30px;
      }
    }

    &__bar-menu {
      display: flex;

      @media (max-width: $screen-sm) {
        display: none;
      }

      &__bar {
        margin: 29px 20px 0;
      }
    }

    &__timeline-component{
      display: flex;
      overflow: auto;
      position: relative;
      padding-right: 300px;
      padding-top:30px;

      &__date {
        width: 0;
        margin-left: 120px;

        @media (max-width: $screen-sm) {
          margin-left: 34px;
        }
      }

      &__bar {
        margin-left: 146px;
        height: 450px;
        border: 1px dashed rgba(36, 36, 36, 0.33);

        &.after-2013 {
          margin-left: calc(146px - 15px);
        }

        &.after-2015 {
          margin-left: calc(146px - 93px);
        }

        &.after-2017 {
          margin-left: calc(146px - 89px);
        }

        &.after-2019 {
          margin-left: calc(146px - 11px);
        }

        &.after-2020 {
          margin-left: calc(146px - 67px);
        }

        &.event-2013 {
          margin-left: 15px;
          height: 47px;
          margin-top: 403px;
          border: 1px dashed rgba(36, 36, 36, 0.86);
        }

        &.event-2015 {
          margin-left: 93px;
          height: 47px;
          margin-top: calc(450px - 47px);
          border: 1px dashed rgba(36, 36, 36, 0.86);
        }

        &.event-2017 {
          margin-left: 89px;
          height: 311px;
          margin-top: calc(450px - 311px);
          border: 1px dashed rgba(36, 36, 36, 0.86);
        }

        &.event-2019 {
          margin-left: 15px;
          height: 47px;
          margin-top: calc(450px - 47px);
          border: 1px dashed rgba(36, 36, 36, 0.86);
        }

        &.event-2020 {
          margin-left: 67px;
          height: 311px;
          margin-top: calc(450px - 311px);
          border: 1px dashed rgba(36, 36, 36, 0.86);
        }

        &.event-2021 {
          margin-left: -2px;
          height: 133px;
          border: 1px dashed rgba(36, 36, 36, 0.86);
          margin-top: calc(450px - 133px);
        }

        @media (max-width: $screen-sm) {
          margin-left: 48px;
          height: 407px;

          &.event-2020 {
            margin-left: 188px;
          }

          &.after-2013 {
            margin-left: calc(48px - 15px);
          }

          &.event-2013 {
            margin-top: calc(407px - 43px);
          }

          &.event-2015 {
            margin-top: calc(407px - 47px);
          }

          &.event-2017 {
            margin-top: calc(407px - 311px);
          }

          &.event-2019 {
            margin-top: calc(407px - 47px);
          }

          &.event-2020 {
            margin-top: calc(407px - 311px);
          }

          &.event-2021 {
            margin-top: calc(407px - 311px);
          }

          &.after-2015 {
            margin-left: calc(48px - 93px);
          }

          &.after-2017 {
            margin-left: calc(48px - 89px);
          }

          &.after-2019 {
            margin-left: calc(48px - 11px);
          }

          &.after-2020 {
            margin-left: calc(48px - 67px);
          }
        }
      }

      &__event-component {
        position: absolute;
        margin-left: 163px;
        margin-top: 223px;

        &__triangle {
          border: 10px solid transparent;
          border-left: 10px solid $background-tertiary;
          border-top: 10px solid $background-tertiary;

          &.event-2015, &.event-2021 {
            border: 10px solid transparent;
            border-left: 10px solid $background-primary;
            border-top: 10px solid $background-primary;
          }
        }

        &__content {
          width: 400px;
          border-radius: 8px 8px 8px 0px;
          background-color: $background-tertiary;
          padding: 15px 30px;

          &__img-container {
            width: 100%;
            text-align: center;

          }

          &__img {
            max-width: 266px;
            max-height: 236px;
          }

          &.event-2015 {
            background-color: $background-primary;
          }

          &.event-2021 {
            background-color: $background-primary;
          }

          @media (max-width: $screen-sm) {
            width: 220px;
            padding: 5px 15px;

            &__img {
              display: none;
            }
          }
        }

        &.event-2015 {
          margin-left: 838px;
          margin-top: -30px;
        }

        &.event-2017 {
          margin-left: 1424px;
          margin-top: -30px;
        }

        &.event-2019 {
          margin-left: 1944px;
        }

        &.event-2020 {
          margin-top: 0;
          margin-left: 2299px;
        }

        &.event-2021 {
          margin-top: 190px;
          margin-left: 2527px;
        }

        @media (max-width: $screen-sm) {
          margin-left: 66px;
          margin-top: calc(235px - 43px);

          &.event-2015 {
            margin-left: 344px;
            margin-top: 250px;
          }

          &.event-2017 {
            margin-left: 542px;
            margin-top: 0;
          }

          &.event-2019 {
            margin-left: 670px;
            margin-top: 240px;
          }

          &.event-2020 {
            margin-top: 10px;
            margin-left: 950px;
          }

          &.event-2021 {
            margin-top: 158px;
            margin-left: 983px;
          }
        }
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      margin: 0 115px 51px;

      @media (max-width: $screen-sm) {
        margin: 0 0 15px;
        padding-left: 30px;
        padding-right: 30px;
      }

      &__info {
        max-width: 500px;
      }
    }
  }
}

input[type='range'] {
  overflow: hidden;
  width: 296px;
  height: 15px;
  border-radius: 50px;
  -webkit-appearance: none;
  background-color: rgba(36, 36, 36, 0.24);
}

input[type='range']::-webkit-slider-runnable-track {
  height: 15px;
  -webkit-appearance: none;
  background-color: rgba(36, 36, 36, 0.24);
  margin-top: -1px;
}

input[type='range']::-webkit-slider-thumb {
  width: 17px;
  -webkit-appearance: none;
  height: 17px;
  border-radius: 50px;
  border: 3px solid white;
  cursor: pointer;
  background: $color-primary;
  box-shadow: -296px 0 0 296px rgba(36, 36, 36, 0.56);
}


input[type="range"]::-moz-range-progress {
  background-color: rgba(36, 36, 36, 0.56);
}
input[type="range"]::-moz-range-track {
  background-color: rgba(36, 36, 36, 0.24);
}
input[type="range"]::-ms-fill-lower {
  background-color: rgba(36, 36, 36, 0.56);
}
input[type="range"]::-ms-fill-upper {
  background-color: rgba(36, 36, 36, 0.24);
}

.new-design-timeline__timeline-component::-webkit-scrollbar {
  display: none;
}



/*
=============================================================================
Links
=============================================================================
*/

a {
  color: $color-link;
  line-height: 150%;
  font-family: $link-text;
  font-size: 20px;

  &:hover, &:focus {
    color: $color-link;
    text-decoration: underline;
  }

  &.button, &.white-text-on-hover {
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
}

