
/* $box-shadow-image-button: ; */
$outline-image-button: #E20246 auto 3px;

$emerald-green: #0D9A86;
$light-blue: #66C0FF;
$cloudy-stone: #D7D7E6;
$asthma-stone: #696B8A;
$stone-grey: #696B8D;
$grey: #e8ebee; // birth control popular brands
$cool-cobalt: #485FCE;
$color-link: #125C87;
$blue-HSV1: #485FCE;

$orange-CP: #F15F5E;     // Birth Control Pill

$pink-EDA: #E20645;      // EDA
$pink---OLD: #F45E5E;
$red-HSV1: #E20246;
$eerie-black: #1b1b1b;

$color-primary: #093d26;
$color-secondary: #002312;
$color-positive: #00a676;
$color-warning: #f06c45;
$color-accent: #fff731;
$color-negative: #d35269;
$color-lemonaid-yellow: #fff731;

$background-primary: #e9f2e6;
$background-secondary: #e6f6ff;
$background-tertiary: #fff5db;
$background-quaternary: #f3f6f9;
$border-opaque: #bbc7ce;
$border-transparent: transparent;

$color-font: #242424;
/* $color-link: #125c87; */
$color-link: #1981BB;
$color-faq-item: rgba(36, 36, 36, .68);

$white: #fff;
$black: #000;
$gray-lighter:           #f1f1f1;
$gray-light:             #c6c6c6;
$gray:                   #AFAFAF;
$gray-dark:              #585858;
/*$gray-darkish:		 #888888; Used in header for list titles*/
$gray-darker:            lighten($black, 25%); // #222
$gray-testimonials:      #999999;
$column-description:     #47484A;  // Changed by Meryl for all services (How Visit Works)
$teal: #3fbeb5;
$purple: #8b69ae;
$yellow: #fdc711;
$pale-yellow: #ffffae;
$orange: #faa519;
$orange-Lemonaid: #FDC711;
$blue-CPpatch: #504BCF;     // Birth Control Patch
$green-CPring: #069F74;     // Birth Control Ring
$cloudy-sky-MIG: #9299AF;   // Migraine
$blue-depression: #02408A;
$mh-anxiety: #B6338A;
$orange-ACNE: #FF842B;
$midnight: #030A34;
$blue: #39a2d2;
$blue-HC: #2B8AE7;
$blue-PE: #39a2d2;
$pink-FLU: #ee6579;
$pink-STD: #DD64A9;
$green: #B3C126;
$green-A1C: #89C270;
$red: #f16531;
$red-ABO: #E04848;
$lapis-Blue-SC: #02408A;
$teal-EDX: #3FBEB5;
$teal-EDR: #3FBEB5;
$purple-HLX: #A068D6;
$purple-insomnia: #8B93C6;
$purple-SKDS: #891E4A;  // Dark Spot
$fandango-MH: #B6338A;
$hotcoral: #F45E5E;
$hypothyroid-TSH: #3AA2d1;
$wellness-WC: #8b69ae;
$therapy-TH: #3FBFB5;
$dark-grayish-blue: #6D7588;

$brand-primary:         $orange;
$brand-danger:          #EB1452;
$brand-success: 		#5cb85c;

$navbar-default-link-color: $gray-dark;
$navbar-default-link-hover-color: $gray-darker;

$subheader-height: 65px;

$link-hover-decoration: none;

$regular-text: 'littera_regular', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$default-text: 'Freizeit Lemonaid', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$light-text: 'littera_light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$medium-text: 'littera_medium', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$bold-text: 'Freizeit Lemonaid', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$link-text: 'Atkinson Hyperlegible', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-icons-base: 'icomoon';
$font-icons-rebrand: 'icomoon-rebrand';

$space-xs : 15px;
$space-s : 30px;
$space-m : 60px;
$space-lg : 90px;
$space-xlg: 120px;
$screen-tablet: 1024px;
$screen-xlg: 1200px;
$screen-laptop: 1440px;
$screen-sm: 768px;
$screen-md: 992px;
