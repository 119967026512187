h1, h2, h3, h4, h5, h6 {
  font-family: $bold-text;
  color: $color-secondary;
}

h1 {
  font-size: 64px;
  font-weight: 400;
  line-height: 68px;

  @media (max-width: $screen-sm) {
    font-size: 36px;
    line-height: 41px;
  }
}

h2 {
  font-size: 36px;
  font-weight: 400;
  line-height: 44px;

  @media (max-width: $screen-sm) {
    font-size: 26px;
    line-height: 32px;
  }
}

h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;

  @media (max-width: $screen-sm) {
    font-size: 18px;
    line-height: 22px;
  }
}

h4 {
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

p {
  line-height: 30px;
  font-family: 'Atkinson Hyperlegible', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 20px;

  &.labels {
    font-size: 14px;
    line-height: 17px;
  }

  a {
    font-size: inherit;
  }

  @media (max-width: $screen-sm) {
    font-size: 18px;
    line-height: 22px;
  }
}

ul li a {
  font-size: inherit;
}

/*
=============================================================================
Font Definitions
=============================================================================
*/
@font-face {
  font-family: 'littera_bold';
  src: url('../fonts/litteratextbold-webfont.eot');
  src: url('../fonts/litteratextbold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/litteratextbold-webfont.woff2') format('woff2'),
  url('../fonts/litteratextbold-webfont.woff') format('woff'),
  url('../fonts/litteratextbold-webfont.ttf') format('truetype'),
  url('../fonts/litteratextbold-webfont.svg#littera_textbold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'littera_book';
  src: url('../fonts/litteratextbook-webfont.eot');
  src: url('../fonts/litteratextbook-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/litteratextbook-webfont.woff2') format('woff2'),
  url('../fonts/litteratextbook-webfont.woff') format('woff'),
  url('../fonts/litteratextbook-webfont.ttf') format('truetype'),
  url('../fonts/litteratextbook-webfont.svg#littera_textbook') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'littera_light';
  src: url('../fonts/litteratextlight-webfont.eot');
  src: url('../fonts/litteratextlight-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/litteratextlight-webfont.woff2') format('woff2'),
  url('../fonts/litteratextlight-webfont.woff') format('woff'),
  url('../fonts/litteratextlight-webfont.ttf') format('truetype'),
  url('../fonts/litteratextlight-webfont.svg#littera_textlight') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'littera_medium';
  src: url('../fonts/litteratextmedium-webfont.eot');
  src: url('../fonts/litteratextmedium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/litteratextmedium-webfont.woff2') format('woff2'),
  url('../fonts/litteratextmedium-webfont.woff') format('woff'),
  url('../fonts/litteratextmedium-webfont.ttf') format('truetype'),
  url('../fonts/litteratextmedium-webfont.svg#littera_textmedium') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'littera_regular';
  src: url('../fonts/litteratextregular-webfont.eot');
  src: url('../fonts/litteratextregular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/litteratextregular-webfont.woff2') format('woff2'),
  url('../fonts/litteratextregular-webfont.woff') format('woff'),
  url('../fonts/litteratextregular-webfont.ttf') format('truetype'),
  url('../fonts/litteratextregular-webfont.svg#littera_text_regularregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Freizeit Lemonaid';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/FreizeitLemonaid-Light.woff2') format('woff2'),
  url('../fonts/FreizeitLemonaid-Light.woff') format('woff');
}

@font-face {
  font-family: 'Freizeit Lemonaid';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/FreizeitLemonaid-Regular.woff2') format('woff2'),
  url('../fonts/FreizeitLemonaid-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Freizeit Lemonaid';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/FreizeitLemonaid-Bold.woff2') format('woff2'),
  url('../fonts/FreizeitLemonaid-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Atkinson Hyperlegible';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Atkinson-Hyperlegible-Regular-102.otf') format('opentype');
}

@font-face {
  font-family: 'Atkinson Hyperlegible';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Atkinson-Hyperlegible-Bold-102.otf') format('opentype');
}
