.button {
  display: inline-block;
  font-size: 16px;
  padding: 10px 50px;
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  border: none;
  transition: all .333s;
  font-family: $default-text;

  &_primary {
    color: $white;
    background: $color-primary;
    box-shadow: 0px 10px 35px rgba(9, 61, 38, 0.18), 0px 7px 18.6158px rgba(9, 61, 38, 0.124197), 0px 3.89859px 9.03764px rgba(9, 61, 38, 0.100132), 0px 1.91116px 4.43042px rgba(9, 61, 38, 0.0798677), 0px 0.755676px 1.7518px rgba(9, 61, 38, 0.0558033);

    &:hover, &:focus {
      color: $white;
    }

    &:hover {
      transform: translateY(-5px);
    }

    &:active {
      transform: translateY(1px);
    }
  }

  &_secondary {
    color: $color-primary;
    border: 2px solid $color-primary;
    background: $white;

    &.viagra {
      padding-left: 42px;
      padding-right: 42px;
    }

    &:hover {
      background: $background-primary;
    }
  }
}

.get-price-button {
  width: 175px;
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  background-color: $pink-EDA;
  color: white !important;
  border: 4px solid $pink-EDA;
  border-radius: 40px;

  @media (max-width: $screen-sm) {
    height: 50px !important;
    width: 240px;
    font-size: 20px;
    font-weight: 0;
    padding: 6px 10px 8px;
  }
}

.midnight-color {
  color: $midnight;
}

.get-started-button {
  /* min-width: 360px; */
  min-width: 340px;
  border-radius: 40px;
  font-size: 30px;
  font-weight: 700;
  background: white;
  border: 4px solid white;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  /* padding: 8px 90px 10px; */
  padding: 8px 60px 10px;

  &.mh-assessment {
    color: #f16533;
    font-size: 20px;
  }

  &.insomnia {
    color: #f16533;
    font-size: 20px;

    &:hover, &:focus {
      color: $white;
      border: 4px solid $white;
      background-color: transparent;
    }
  }

  &.start-assessment {
    min-width: 313px;
    font-size: 24px;

    @media (max-width: $screen-sm) {
      min-width: 211px;
      font-size: 18px;
      padding: 8px 10px 8px;
      height: 50px;
    }

    @media (max-width: 1000px) and (min-width: $screen-sm) {
      min-width: 100px;
      font-size: 18px;
    }
  }

  &.start-insomnia-treatment, &.start-hypertension-treatment, &.start-asthma-treatment {
    min-width: 313px;
    font-size: 24px;

    @media (max-width: $screen-sm) {
      min-width: 211px;
      font-size: 18px;
      padding: 8px 10px 8px;
      height: 50px;
    }
  }

  &.covid-19 {
    font-size: 24px;
    padding: 8px 66px 10px;
    min-width: unset;

    @media (max-width: $screen-sm) {
      font-size: 18px;
      /* padding: 6px 48px 8px; */
      padding: 6px 64px 8px;
    }
  }

  &.singlecare {
    width: 240px;
  }

  &.anxiety-coaching {
    padding: 8px 13px 10px;

    @media (max-width: $screen-sm) {
      padding: 6px 40px 8px;
    }
  }

  &.ed-options-learn-more {
    font-size: 20px;
    padding: 6px 60px 6px;
    min-width: unset;
    font-family: $medium-text;
    font-weight: normal;

    @media (max-width: $screen-sm) {
      font-size: 16px;
      padding: 4px 45px 5px;
    }
  }

  &.ed-refill-popup {
    font-size: 20px;
    padding: 4px 40px;
    min-width: unset;

    @media (max-width: $screen-sm) {
      padding: 4px 30px;
    }
  }

  &.cialis-button {

    @media (max-width: $screen-sm) {
      min-width: 190px;
      font-size: 20px;
      padding: 6px 40px 8px;
    }
  }

  &.sign-in {
    padding-top: 15px !important;
  }

  &.dark-spot-button {
    color: #95194C;

    &:hover, &:focus {
      color: $white;
    }
  }

  &.hotflashes-steps-button {
    color: #9299AF;

    &:hover, &:focus {
      color: $white;
    }
  }

  &.blue-depression-button {
    color: $blue-depression;

    &:hover, &:focus {
      color: $white;
    }
  }

  &.anxiety-button {
    color: #A04882;

    &:hover, &:focus {
      color: $white;
    }
  }

  &.erectile-dysfunction-auto-delivery {
    background-color: $white;
    color : $hotcoral;
    border: 4px solid $white;

    &:hover, &:focus {
      color: $white;
    }
  }

  &.erectile-dysfunction {
    background-color: $white;
    color : $red;
    border: 4px solid $white;

    &:hover, &:focus {
      color: $white;
    }
  }

  &.red-button {
    background-color: $red;
    color : $white;
    border: 4px solid $red;

    &:hover, &:focus {
      color: $red;
    }
  }

  &.white-seafoam-button {
    color: #3FBEB5;
    border: 4px solid $white;
    background-color: $white;

    &:hover, &:focus {
      color: $white;
      border: 4px solid $white;
      background-color: transparent;
    }
  }

  &.white-seafoam-inverse-button {
    color: $white;
    border: 4px solid $white;
    background-color: transparent;

    &:hover, &:focus {
      color: #3FBEB5;
      border: 4px solid $white;
      background-color: $white;
    }
  }

  &.purple-button {
    color: #8F69AD;
    background-color: $white;
    border: 4px solid $white;

    &:hover, &:focus {
      color: $white;
    }
  }

  &.white-purple2-button {
    color: #776AE3;
    background-color: $white;
    border: 4px solid $white;

    &:hover, &:focus {
      color: $white;
      border: 4px solid $white;
      background-color: transparent;
    }
  }

  &.purple2-button {
    color: $white;
    background-color: #776AE3;
    border: 4px solid #776AE3;

    &:hover, &:focus {
      color: #776AE3;
      border: 4px solid #776AE3;
      background-color: transparent;
    }
  }

  &.purple2-inverse-button {
    color: #776AE3;
    border: 4px solid #776AE3;
    background-color: transparent;

    &:hover, &:focus {
      color: $white;
      background-color: #776AE3;
      border: 4px solid #776AE3;
    }
  }

  &.white-lightblue-button {
    color: #7BC2DD;
    border: 4px solid $white;
    background-color: $white;

    &:hover, &:focus {
      color: $white;
      border: 4px solid $white;
      background-color: transparent;
    }
  }

  &.white-lightblue-inverse-button {
    color: $white;
    border: 4px solid $white;
    background-color: transparent;

    &:hover, &:focus {
      color: #7BC2DD;
      border: 4px solid $white;
      background-color: $white;
    }
  }

  &.white-darkgrey-button {
    color: #393E46;
    border: 4px solid $white;
    background-color: $white;

    &:hover, &:focus {
      color: $white;
      border: 4px solid $white;
      background-color: transparent;
    }
  }

  &.white-taupe-button {
    color: #857671;
    border: 4px solid $white;
    background-color: $white;

    &:hover, &:focus {
      color: $white;
      border: 4px solid $white;
      background-color: transparent;
    }
  }

  &.white-orange-button {
    color: #F16531;
    border: 4px solid $white;
    background-color: $white;

    &:hover, &:focus {
      color: $white;
      border: 4px solid $white;
      background-color: transparent;
    }
  }

  &.white-orange-inverse-button {
    color: $white;
    border: 4px solid $white;
    background-color: transparent;

    &:hover, &:focus {
      color: #F16531;
      border: 4px solid $white;
      background-color: $white;
    }
  }

  &.blue-button {
    color: $white;
    background-color: #191970;
    border: 4px solid #191970;

    &:hover, &:focus {
      color: #191970;
      border: 4px solid #191970;
      background-color: transparent;
    }
  }

  &.yellow-blue-button {
    color: #191970;
    border: 4px solid #FDF484;
    background-color: #FDF484;

    &:hover, &:focus {
      color: $white;
      border: 4px solid #191970;
      background-color: #191970;
    }
  }

  &.midnight-button {
    color: $white;
    border: 4px solid $midnight;
    background-color: $midnight;

    &:hover, &:focus {
      color: $midnight;
      border: 4px solid $midnight;
      background-color: transparent;
    }
  }

  &.white-midnight-button {
    color: $midnight;

    &.small-border {
      border-width: 2px;
    }

    &.gh-button {

      @media (max-width: $screen-sm) {
        padding: 6px 70px 8px;
        font-size: 20px;
      }
    }

    &:hover, &:focus {
      color: $white;
    }
  }

  &.pink-inverse-pink-border {
    background-color: $white;
    color: $pink-EDA;
    border: 4px solid $pink-EDA;

    &.small-border {
      border-width: 2px;
    }

    &:hover, &:focus {
      background-color: $pink-EDA;
      color: $white;
      border: 4px solid $pink-EDA;

      &.small-border {
        border-width: 2px;
      }
    }
  }

  // for pink button on pink background
  &.pink-inverse {
    background-color: $white;
    color: $pink-EDA;
    border: 4px solid $white;

    &:hover, &:focus {
      background-color: transparent;
      color: $white;
      border: 4px solid $white;
    }
  }

  &.pink {
    color: $white;
    border: 4px solid $pink-EDA;
    background-color: $pink-EDA;

    &:hover, &:focus {
      color: $pink-EDA;
      border: 4px solid $pink-EDA;
      background-color: $white;
    }

    &.hover-transparent-pink:hover, &.hover-transparent-pink:focus {
      color: $white;
      border: 4px solid $pink-EDA;
      background-color: transparent;

      @media (max-width: $screen-sm) {
        color: $pink-EDA;
        border: 4px solid $pink-EDA;
      }
    }

    &.hover-white-pink:hover, &.hover-white-pink:focus {
      color: $white;
      border: 4px solid $white;
      background-color: transparent;

      @media (max-width: $screen-sm) {
        color: $pink-EDA;
        border: 4px solid $pink-EDA;
      }
    }

    &.hover-pink-white:hover, &.hover-pink-white:focus {
      color: $pink-EDA;
      border: 4px solid $pink-EDA;
      background-color: transparent;

      @media (max-width: $screen-sm) {
        color: $white;
        border: 4px solid $white;
      }
    }

    &.hover-white-white:hover, &.hover-white-white:focus {
      color: $white;
      border: 4px solid $white;
      background-color: transparent;
    }

    &.hover-pink-pink:hover, &.hover-pink-pink:focus {
      color: $pink-EDA;
      border: 4px solid $pink-EDA;
      background-color: transparent;
    }

    &.homepage-button {
      padding: 6px 30px 8px;
      font-size: 24px;
      min-width: unset;

      @media (min-width: $screen-sm) and (max-width: 830px) {
        padding: 6px 30px 8px;
        font-size: 20px;
        min-width: unset;
      }

      @media (max-width: ($screen-sm - 1)) {
        padding: 6px 30px 8px;
        font-size: 20px;
        min-width: unset;
      }
    }

    &.zava-page {
      width: 100%;
      min-width: unset;
      padding: 2px 70px 3px;
      font-size: 24px;
      border-radius: 4px;

      background-color: $color-primary;
      border: 2px solid $color-primary;

      &.thank-you {
        padding: 2px 30px 3px;
        width: 360px;

        @media (max-width: $screen-sm) {
          padding: 4px 50px;
          width: 300px;
        }

        &:hover, &:focus {
          border: 2px solid #EF570D;

          @media (max-width: $screen-sm) {
            border: 2px solid #EF570D;
          }
        }
      }

      @media (max-width: $screen-sm) {
        padding: 4px 50px 4px;
        font-size: 20px;
      }

      &:hover, &:focus {
        background-color: $white;
        color: $color-primary;
        border: 2px solid $white;

        @media (max-width: $screen-sm) {
          background-color: $white;
          color: $color-primary;
          border: 2px solid $white;
        }
      }
    }

    &.lloyds-services {
      padding: 6px 10px 7px;
      width: 350px;
      margin-bottom: 40px;
      min-width: unset;
      font-size: 24px;

      &.erectile-dysfunction {
        background-color: #F16531;
        border: 2px solid #F16531;
      }

      &.erectile-dysfunction:hover, &.erectile-dysfunction:focus {
        background-color: white;
        color: #F16531;
      }

      &.birth-control {
        background-color: #F16675;
        border: 2px solid #F16675;
      }

      &.birth-control:hover, &.birth-control:focus {
        background-color: white;
        color: #F16675;
      }

      &.hair-loss {
        background-color: #AD6ECE;
        border: 2px solid #AD6ECE;
      }

      &.hair-loss:hover, &.hair-loss:focus {
        background-color: white;
        color: #AD6ECE;
      }

      @media (max-width: $screen-sm) {
        padding: 5px 10px 6px;
        font-size: 20px;
        width: 80%;
      }
    }

    &.lloyds-pharmacy-green {
      min-width: unset;
      padding: 10px 50px;
      font-size: 16px;
      font-weight: 400;
      font-family: $default-text;
      width: 60%;
      color: $white;
      background: $color-primary;
      border: 2px solid $color-primary;
      border-radius: 4px;

      @media (max-width: $screen-sm) {
        width: 80%;
      }

      &:hover, &:focus {
        background-color: $color-primary;
        color: $white;
        border: 2px solid $color-primary;
      }
    }

    &.lloyds-pharmacy {
      min-width: unset;
      padding: 6px 70px 7px;
      font-size: 24px;
      width: 60%;
      background-color: #F16675;
      border: 2px solid #F16675;

      @media (max-width: $screen-sm) {
        padding: 5px 50px 6px;
        font-size: 20px;
        width: 80%;
      }

      &:hover, &:focus {
        background-color: $white;
        color: #F16675;
        border: 2px solid #F16675;

        @media (max-width: $screen-sm) {
          background-color: $white;
          color: #F16675;
          border: 2px solid #F16675;
        }
      }
    }

    &.come-back {

      @media (max-width: $screen-sm) {
        padding: 8px 60px 10px;
      }

      @media (min-width:769px) and (max-width:1015px) {
        padding: 10px 60px 12px;
      }
    }

    &.hover-purple {
      background-color: #8b69ae;
      color: white;
      border: 4px solid #8b69ae;

      &:hover, &:focus {
        background-color: white;
        color: #8b69ae;
        border: 4px solid #8b69ae;
      }
    }

    &.hover-migraine {
      background-color: white;
      color: #9299AF;
      border: 4px solid white;

      &:hover, &:focus {
        background-color: transparent;
        color: white;
        border: 4px solid white;
      }
    }
  }

  &.mh-blue {
    background-color: #0D1233;
    color: $white;
    border: 4px solid #0D1233;

    &:hover, &:focus {
      background-color: transparent;
      color: #0D1233;
      border: 4px solid #0D1233;
    }
  }

  &.henital-herpes-button {
    background: white;
    color: rgb(244, 94, 94);
    border: 4px solid white;
  }

  &.hypothyroid-button {
    background: white;
    color: $hypothyroid-TSH;
    border: 4px solid white;
  }

  &.depression-button {
    background: white;
    color: $fandango-MH;
    border: 4px solid white;

    @media (max-width: $screen-sm) {
      padding: 6px 30px 8px;
    }
  }

  &.urgent-call-button {
    font-size: 24px;
    width: 180px;
    min-width: unset;
    padding: 5px 20px 7px;

    @media (max-width: $screen-sm) {
      font-size: 22px;
    }
  }

  &.mh-landing-page {
    background-color: #B53389;
    color: white;
    border: 4px solid #B53389;
    box-shadow: 0px 3px 3px black;
    padding: 8px 40px 10px;

    &.mh-pills {

      @media (max-width: $screen-sm) {
        font-size: 18px;
        padding: 10px 25px 11px;
      }
    }

    @media (max-width: $screen-sm) {
      font-size: 20px;
      padding: 10px 38px 11px;
    }

    &:hover, &:focus {
      background-color: white;
      color: #B53389;
      border: 4px solid #B53389;
      box-shadow: none;
    }
  }

  &.cialis-drug-page {
    background-color: white;
    color: #3FBEB5;
    border: 4px solid white;

    &:hover, &:focus {
      background-color: transparent;
      color: white;
      border: 4px solid white;
    }
  }

  // for 'Get Price' button
  &.get-price {
    width: 275px !important;
    font-size: 21px;
  }

  &.get-started-ed {
    width: 275px;
    font-size: 17px;
  }

  // for 'Find A Quest Near You' button
  &.quest-near-you {
    font-size: 25px;

    @media (max-width: $screen-sm) {
      font-size: 21px;
    }
  }

  &.learn-more-button {
    font-size: 28px !important;

    @media (max-width: $screen-sm) {
      font-size: 20px !important;
    }
  }

  // for PE
  &.pe-start-visit {
    color: $blue-PE;

    @media (max-width: $screen-sm) {
      border: 4px solid $blue-PE;
    }
  }

  //for yasmin
  &.yasmin-start-visit {
    color: $orange-CP;

    @media (max-width: $screen-sm) {
      /* border: 4px solid $orange-CP; */
    }

    &:hover, &:focus {

      @media (max-width: $screen-sm) {
        border-color: #fff;
      }
    }
  }

  // for birth control pills
  &.birth-control-start-visit {
    color: $orange-CP;

    @media (max-width: $screen-sm) {
      border: 4px solid $orange-CP;
    }
  }

  // for birth control ring
  &.birth-control-ring-start-visit {
    color: $green-CPring;

    @media (max-width: $screen-sm) {
      border: 4px solid $green-CPring;
    }
  }

  // for birth control patch
  &.birth-control-patch-start-visit {
    color: $blue-CPpatch;

    @media (max-width: $screen-sm) {
      border: 4px solid $blue-CPpatch;
    }
  }

  // for ED
  &.ed-start-visit {
    color: $red;

    @media (max-width: $screen-sm) {
      border: 4px solid $red;
    }

    &:hover, &:focus {
      color: $white;
    }
  }

  // for UTI
  &.uti-start-visit {
    color: $teal;

    @media (max-width: $screen-sm) {
      border: 4px solid $teal;
    }

    &:hover, &:focus {
      color: $white;
    }
  }

  // for sinus infection
  &.sinus-infection-start-visit {
    color: $blue;

    @media (max-width: $screen-sm) {
      border: 4px solid $blue;
    }
  }

  // for cold sore
  &.cold-sore-start-visit {
    color: $red-HSV1;

    @media (max-width: $screen-sm) {
      border: 4px solid $red-HSV1;
    }
  }

  // for hair loss
  &.hair-loss-start-visit {
    color: $purple;

    @media (max-width: $screen-sm) {
      border: 4px solid $purple;
    }
  }

  // for hair loss direct (delivery)
  &.hair-loss-direct {
    color: $purple-HLX;

    @media (max-width: $screen-sm) {
      border: 4px solid $purple-HLX;
    }
  }

  // for stop smoking
  &.stop-smoking-start-visit {
    color: $lapis-Blue-SC;

    @media (max-width: $screen-sm) {
      border: 4px solid $lapis-Blue-SC;
    }
  }

  // for acid reflux
  &.acid-reflux-start-visit {
    color: $green;

    @media (max-width: $screen-sm) {
      border: 4px solid $green;
    }
  }

  // for flu
  &.flu-start-visit {
    color: $pink-FLU;

    @media (max-width: $screen-sm) {
      border: 4px solid $pink-FLU;
    }
  }

  // for cholesterol-statin-treatment
  &.cholesterol-statin-treatment-start-visit {
    color: $blue-HC;

    @media (max-width: $screen-sm) {
      border: 4px solid $blue-HC;
    }
  }

  // for std testing
  &.std-testing-start-visit {
    color: $pink-STD;

    @media (max-width: $screen-sm) {
      border: 4px solid $pink-STD;
    }
  }

  // for a1c blood sugar test
  &.a1c-blood-sugar-test-start-visit {
    color: $green-A1C;

    @media (max-width: $screen-sm) {
      border: 4px solid $green-A1C;
    }
  }

  // for blood type test
  &.blood-type-test-start-visit {
    color: $red-ABO;

    @media (max-width: $screen-sm) {
      border: 4px solid $red-ABO;
    }
  }

  &.sprintec {
    color: #d2efb0;

    @media (max-width: $screen-sm) {
      border: 4px solid #d2efb0;
    }
  }

  &.errin {
    color: #bbddea;

    @media (max-width: $screen-sm) {
      border: 4px solid #bbddea;
    }
  }

  &.ed-meds {
    color: #f16533;

    @media (max-width: $screen-sm) {
      border: 4px solid #f16533;
    }
  }

  &.tadalafil {
    color: #16c086;

    @media (max-width: $screen-sm) {
      border: 4px solid #16c086;
    }
  }

  &.cialis {
    color: #1c92b2;

    @media (max-width: $screen-sm) {
      border: 4px solid #1c92b2;
    }
  }

  // for depression
  &.anxiety-depression-start-visit {
    color: $fandango-MH;

    @media (max-width: $screen-sm) {
      border: 4px solid $fandango-MH;
    }
  }

  // for hypothyrodism
  &.hypothyrodism-start-visit {
    color: $red-HSV1;

    @media (max-width: $screen-sm) {
      border: 4px solid $hypothyroid-TSH;
    }
  }

  &.width-100-percent {
    width: 100%;
  }

  &.small-side-padding {
    padding: 8px 32px 10px;

    @media (max-width: $screen-sm) {
      padding: 6px 24px 8px;
    }

    @media (min-width:769px) and (max-width:1015px) {
      padding: 8px 32px 10px;
    }
  }

  @media (max-width: $screen-sm) {
    min-width: 200px;
    font-size: 22px;

    // DO NOT INCREASE
    // IF YOU INCREASE, THE YOU WILL BREAK SOME BUTTONS
    // LIKE "Learn more about ED" ON MOBILE SMALL SCREENS
    padding: 6px 35px 8px;
  }

  @media (min-width:769px) and (max-width:1015px) {
    min-width: 300px;
    font-size: 26px;
    padding: 6px 40px 8px;
  }

  &:hover, &:focus {
    background-color: transparent;
    color: white;
    border-color: white;
  }
}
