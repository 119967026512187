.navbar-header {
  height: 94px;

  @media (max-width: 767px) {
    height: 62px;
    border-bottom: none;
  }

  &:before, &:after {
    display: block;
    content: none;
  }

  &__desktop {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 0 40px;

    @media (max-width: $screen-xlg) {
      display: none;
    }
  }

  &__mobile {
    display: none;

    .icon-ic-burger:before {
      content: "\e90e";
      font-family: $font-icons-rebrand;
    }

    .mobile-menu-icon {
      font-size: 30px;
    }

    @media (max-width: $screen-xlg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__logo {
    justify-self: center;

    img {
      width: 150px;

      @media (max-width: $screen-sm) {
        width: 100px;
      }
    }
  }

  &__links-block {
    display: flex;

    &__item {
      margin-right: 25px;
    }
  }

  &__link {
    color: $color-font;
    cursor: pointer;

    @media (max-width: $screen-laptop) {
      font-size: 16px;
    }

    &:focus, &:hover {
      color: $color-primary;
      text-decoration: none;
    }
  }

  &__auth-block {
    display: flex;
    justify-self: flex-end;
  }

  &__phone-link {
    display: flex;
    align-items: center;
    margin-right: $space-s;
    font-size: 14px;
    line-height: 150%;
    color: $color-font;
  }

  &__auth-holder {
    display: flex;
    align-items: center;
    justify-self: flex-end;
  }

  &__burger {
    padding: 15px 15px 0 0;
  }
}

.navbar {
  position: fixed;
  top: 15px;
  left: 15px;
  right: 15px;
  border: 0;
  border-radius: 5px;
  background: $white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.06), 0px 8px 25px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.035), 0px 1px 5.57042px rgba(0, 0, 0, 0.0243888);

  &:before, &:after {
    display: block;
    content: none;
  }

  .navbar-alert {
    background: $red;
    padding: 6px;
    text-align: center;
    min-height: 40px;

    @media (max-width: $screen-sm) {
      padding: 6px 18px 6px 6px;
    }

    .navbar-alert-text {
      color: white;
      margin: 0;
      position: relative;
      top: 2px;
      z-index: 1;
    }

    .navbar-alert-close {
      cursor: pointer;
      position: relative;
      top: 5px;
      right: 4px;
      color: white;
      z-index: 2;

      @media (max-width: $screen-sm) {
        right: -3px;
      }
    }
  }

  .navbar-alert-cookies {
    background: $brand-danger;
    padding: 6px;
    text-align: center;
    min-height: 60px;

    @media (max-width: $screen-sm) {
      padding: 6px 18px 6px 6px;
    }

    .navbar-alert-text-cookies {
      color: white;
      margin: 20px 0px;
      position: relative;
      top: 2px;
      z-index: 1;
    }
  }

  .navbar-brand {
    /* padding: 0 0 0 15px; */
    /* padding: 9px 0 15px 15px; */
    padding: 9px 0 10px 15px;
    height: unset;

    &.lemonaid-logo-desktop {
      display : none;
      transform: scale(4);

      &.just-logo {
        display : block;
        padding: 13px 15px 15px 15px;

        @media (min-width: $screen-sm) {
          height: 55px;
          padding: 9px 15px 15px 15px;
        }
      }

      @media (max-width: 492px) {
        display : none;
      }

      @media (min-width: 493px) and (max-width: 767px) {
        display: block;
        /* padding: 13px 15px 15px 15px; */
        padding: 12px 15px 13px;
      }

      // 1030px - 904px ==> 126px
      // @media (min-width: 904px)  {
      @media (min-width: 1030px)  {
        display: block;
      }

      &.signed-out {
        @media (min-width: 795px)  {
          display: block;
        }
      }
    }

    img {

      &.lemonaid-logo-desktop {
        width: 208px;
        height: 36px;

        @media (max-width: 767px)  {
          display: none;
        }

        @media (min-width: 1030px)  {
          margin-right: 15px;
        }

        &.just-logo {
          margin-right: 15px;

          @media (max-width: 767px)  {
            display: block;
            width: 147px;
            height: 25px;
            margin-right: unset;
          }
        }

        &.signed-out {
          @media (min-width: 795px)  {
            margin-right: 15px;
          }
        }
      }

      &.lemonaid-logo-mobile {
        width: 147px;
        height: 25px;

        @media (min-width: 768px)  {
          display: none;
        }
      }
    }
  }

  .navbar-brand-icon {
    /*
    display: inline;
    padding: 9px 10px 10px 0;
    margin: 11px 0 0 15px;
    */
    display: inline-block;
    padding: 0 15px 0 15px;
    margin-left: -15px;
    height: 100%;
    float: left;
    font-size: 36px;
    color: $yellow;

    &.lemonaid-logo-desktop {

      &:focus, &:hover {
        /* box-shadow: 0 2px 5px rgba(0,0,0,.16), 0 2px 10px rgba(0,0,0,.12); */
        /* @include z-depth(1); */
      }

      /***** WE HIDE FOR IPHONE 5 OR SIMILAR VERY NARROW *****/
      @media (max-width: 359px) {
        display: none;
      }

      @media (min-width: 360px) and (max-width: 492px) {
        /* display: inline; */
        display: inline-block;
      }

      @media (min-width: 493px) and (max-width: 767px) {
        display: none;
      }

      // 1030px - 904px ==> 126px
      // @media (min-width: 904px)  {
      @media (min-width: 1030px)  {
        display: none;
      }

      &.signed-out {
        @media (min-width: 795px)  {
          display: none;
        }
      }
    }

    i, icon {
      position: relative;
      top: 9px;

      @media (min-width: 360px) and (max-width: 492px) {
        font-size: 25px;
        top: 14px;
        padding-left: 15px;
      }
    }
  }

  .header-line {
    width: 100%;
    height: 1px;
    background-color: $border-opaque;

    &_hide-desktop {
      @media (min-width: $screen-xlg) {
        display: none;
      }
    }
  }

  .phone-desktop {
    padding: 10px 40px;
    display: none;

    &__link {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 150%;
      color: $color-font;
    }
    &__image {
      width: 11px;
      height: 11px;
      margin-right: 8px;
      margin-bottom: 8px;
    }

    @media (max-width: $screen-xlg) {
      display: block;
    }

    @media (max-width: 768px) {
      padding: 7px 20px;
    }
  }

  @media (min-width: 768px) {

    .phone-desktop-no {
      color: $gray-dark;
      font-weight: bold;
      font-size: 18px;

      display: inline-block;
      height: 100%;
      padding-top: 14px;

      span {
        color: $color-link;
      }

      .phone-desktop-image {
        color: $color-link;
      }
    }
  }

  .icon-ic-logo {
    float: left;
    /* left: 10px; */
  }

  .navbar-nav {
    margin-right:0px;

    li {
      font-family: $medium-text;
      font-size: 16px;
    }

    > li.nav-active {
      a {
        &.eda-header-button-primary:after {
          height: 0;
        }

        &.eda-header-button-primary-inverse:after {
          height: 0;
        }

        &:hover:after, &:focus:after {
          background: $gray-dark;
        }
      }
    }

    li.show-on-grow {
      display: none;
    }

    li.signed-in-msg {
      border-left: 1px solid $gray-light;

      &.signed-in-no-border{
        border: none !important;
      }

      a {
        display: table;

        @media (min-width:$screen-sm) and (max-width: $screen-md)  {
          padding-left: 12px;
          padding-right: 6px;
        }

        span {
          display: table-cell;
          vertical-align: middle;
          padding-left: 7px;
        }
      }
    }

    li.signed-in-gift {
      border-left: 1px solid $gray-light;

      &.signed-in-no-border{
        border: none !important;
      }

      a {
        display: table;

        @media (min-width:$screen-sm) and (max-width: $screen-md)  {
          padding-left: 12px;
          padding-right: 6px;
        }

        span {
          display: table-cell;
          vertical-align: middle;
          padding-left: 7px;
        }
      }
    }

    li.signed-in-account {
      a {
        /* padding: 14px 6px 11px 6px; */
        padding-left: 6px;
        display: table;

        @media (min-width:$screen-sm) and (max-width: $screen-md)  {
          padding-left: 6px;
          padding-right: 6px;
        }

        span {
          display: table-cell;
          vertical-align: middle;
          padding-left: 7px;
        }
      }
    }

    li.signed-in-msg, li.signed-in-account, li.signed-in-gift {
      display: none;

      i, icon {
        font-size: 25px;

        &.icon-ic-gift {
          font-size: 30px;
        }
      }
    }

    li.sign-in-btn {
      padding: 6px 0px 7px 12px;
      border-left: 1px solid $gray-light;

      a.btn {
        padding: 6px 12px;

        &.eda-header-button-primary {
          border-radius: 20px;
          border-style: solid;
          border-width: 1px;
          padding-left: 24px;
          padding-right: 24px;
          height: 42px;
          padding-top: 8px;

          color: $white;
          background-color: $pink-EDA;
          border: 2px solid $pink-EDA;

          &:hover, &:focus {
            color: $pink-EDA;
            background-color: $white;
            border: 2px solid $pink-EDA;
          }
        }

        &.eda-header-button-primary-inverse {
          border-radius: 20px;
          border-style: solid;
          border-width: 1px;
          padding-left: 24px;
          padding-right: 24px;
          height: 42px;
          padding-top: 8px;

          color: $pink-EDA;
          background-color: $white;
          border: 2px solid $pink-EDA;

          &:hover, &:focus {
            color: $white;
            background-color: $pink-EDA;
            border: 2px solid $pink-EDA;
          }
        }
      }
    }

    li.start-visit {
      display: none;
    }

    li.start-visit.showed {
      display: block;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 6px;

      a.btn {
        padding:7px 10px;

        color: $white;
        background-color: $pink-EDA;
        border: 2px solid $pink-EDA;

        &:hover, &:focus {
          color: $pink-EDA;
          background-color: $white;
          border: 2px solid $pink-EDA;
        }
      }
    }

    li.sign-in-btn, li.start-visit {
      &:hover:after {
        content: normal;
      }

      a.btn {
        font-size: 18px;
        font-family: $bold-text;
      }

      .badge {
        position: absolute;
        right: 4px;
      }
    }

    &.signed-out {

      li.how-it-works.show-on-grow {
        display:block;
      }

      li.reviews.show-on-grow {
        @media (min-width: 820px)  {
          display:block;
        }
      }

      li.medical-team.show-on-grow {
        @media (min-width: 930px)  {
          display:block;
        }
      }

      li.meet-the-team.show-on-grow {
        display:block;
      }

      li.faq.show-on-grow {
        display:block;
      }
    }

    &.signed-in {

      li.how-it-works.show-on-grow {
        @media (min-width: 850px)  {
          display:block;
        }
      }

      li.reviews.show-on-grow {
        @media (min-width: 920px)  {
          display:block;
        }
      }

      li.medical-team.show-on-grow {
        @media (min-width: 1030px)  {
          display:block;
        }
      }

      li.meet-the-team.show-on-grow {
        @media (min-width: 853px) {
          display:block;
        }
      }

      li.faq.show-on-grow {
        display:block;
      }

      li.signed-in-gift {
        display:block;
      }

      li.signed-in-msg {
        display:block;
      }

      li.signed-in-account {
        display:block;
      }

      li.sign-in-btn {
        display:none;
      }
    }
  }
}

/*
=============================================================================
Mobile Menu Trigger
=============================================================================
*/

.navbar-default .navbar-toggle {
  /*
  margin-top: 7px;
  margin-bottom: 6px;
  */
  border: 0px;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;

  &.phone-mobile {
    /* margin: 15px 10px 10px 0; */
    float: left;
    display: inline-block;
    height: 100%;
    padding: 0;
    margin: 0;

    @media (max-width: 359px) {
      padding-left: 15px;
    }

    @media (min-width: 768px) {
      display: none;
    }
  }

  &:hover {
    background-color: $white;
  }

  &.signed-out {
    .hide-on-signed-out {
      display: none;
    }
  }

  .phone-mobile-image {
    width: 14px;
    height: 14px;
    float: left;
    margin-top: 4px;
    margin-right: 4px;
  }

  .phone-mobile-no {
    font-size: 16px;
    color: $gray-dark;
    font-weight: bold;
    display: inline-block;
    height: 100%;
    padding-top: 14px;

    &:focus, &:hover {
      /* box-shadow: 0 2px 5px rgba(0,0,0,.16), 0 2px 10px rgba(0,0,0,.12); */
      /* @include z-depth(1); */
    }
  }

  span {

    &.mobile-messages {
      display: inline-block;
      height: 100%;
      vertical-align: top;
    }
  }

  .mobile-messages {

    a {
      cursor: pointer;

      &:hover, &:focus {
        text-decoration:none;
      }
    }

    i, icon {
      color: $gray-dark;

      &.icon-ic-gift {
        font-size: 30px;
        padding-right: 10px;
      }

      &.icon-ic-message {
        font-size: 30px;
        padding-right: 10px;
      }

      &.icon-ic-burger {
        font-size: 70px;
        line-height: 0;
        position: relative;
        top: 18px;
        padding-right: 0px;
      }

      @media (min-width: $screen-sm){
        &.icon-ic-message {
          padding-right:20px;
        }

        &.icon-ic-burger {
          padding-right:5px;
        }
      }
    }

    .badge {
      position: absolute;
      top: 0px;
      left: 17px;
      font-family: $medium-text;
      font-weight: normal;
    }

    .mobile-refer-friend-icon {
      height: 100%;
      display: inline-block;
      padding-top: 8px;

      &:focus, &:hover {
        /* box-shadow: 0 2px 5px rgba(0,0,0,.16), 0 2px 10px rgba(0,0,0,.12); */
        @include z-depth(1);
      }
    }

    .mobile-messages-icon {
      height: 100%;
      display: inline-block;
      padding-top: 11px;

      &:focus, &:hover {
        /* box-shadow: 0 2px 5px rgba(0,0,0,.16), 0 2px 10px rgba(0,0,0,.12); */
        @include z-depth(1);
      }
    }
  }

  @media (min-width: $screen-sm){
    margin-top:12px;
  }
}

/*
=============================================================================
Header Services
=============================================================================
*/

.service-dropdown {
  position: fixed;
  top: 106px;
  left: 15px;
  right: 15px;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 0 0 5px 5px;
  padding: 0;
  margin: 0;
  border: none;

  @media(max-width: ($screen-tablet - 1)) {
    top: 0;
    bottom: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: none;
  }

  .main-content {
    height: 458px;
  }
}

.service-list {
  margin-left:0;
  padding-left:0;

  li {
    display: table;
    position:relative;
    padding: .75rem .75rem .75rem .75rem;
    cursor: pointer;
    float:left;
    transition: 250ms;

    &.nav-active {
      background-color: darken($gray-dark, 5%);
    }

/*
    &:hover, &.nav-active {
      background-color: darken($gray-dark, 5%);
    }

    &:hover:after {
      height: 0 !important;
    }

    &:hover:after, &:focus:after {
      height: 0 !important;
    }
*/

    &:after {
      height: 0 !important;
    }

    .media {
      /* max-width: 160px; */
      /* display: table; */
      display: block;
      border-collapse: separate;
      border-spacing: 10px;
      height: 50px;
      width: 162px;
    }

    a.link-header-desktop {
      /* display: table-cell; */
      display: inline-table;
      color: unset;
      padding: 0;
      width: 100%;
      height: 100%;

      &:hover, &:focus {
        background-color: darken($gray-dark, 5%);
      }

/*
      &:hover:after, &:focus:after {
        height: 0 !important;
      }
*/

      &:after {
        height: 0 !important;
      }
    }

    .media-left {
      /* margin-right: 10px; */
      /* vertical-align: middle; */
      margin-right: 9px;
      padding-right: 0;
      vertical-align: baseline;
      display: inline-block;
    }

    i.media-object, icon.media-object {
      /* font-size: 30px; */
      font-size: 10px;
    }

    .media-body {
      /* display: table-cell; */
      font-size: 13px;
      font-family: $bold-text;
      vertical-align: middle;
      line-height: 15px;
      display: inline-block;
      width: calc(100% - 23px);
    }

    @media(max-width: ($screen-tablet - 1)) {
      display: block;
    }
  }
}


/*
=============================================================================
Mobile Nav
=============================================================================
*/

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  //overflow-y: auto;
  z-index: 50000;
  background-color: rgba(36, 36, 36, 0.36);;
  transform: translateX(100%);
  transition: 250ms ease-in-out;

  /* Slide header in */
  &.open {
    visibility: visible;
    transform: translateX(0);
  }

  /* Make sure mobile nav hides on desktop */
  @media(min-width: $screen-xlg) {
    display: none;
  }

  &__content {
    height: 95%;
    width: 92%;
    background-color: $white;
    padding: 0 26px;
    margin: 15px 0 0 15px;

    /* Restyle close button */
    .close {
      position: absolute;
      top: 30px;
      right: 40px;
      float: none;
      font-size:28px;
      opacity: 1;
      text-align: center;
      font-weight:normal;
      text-shadow: none;

      @media(min-width: $screen-sm) {
        right: 80px;
      }
      // &:hover, &:focus {
      //   background: darken($gray-dark, 5%);
      // }
    }

    .btn-holder {
      display:none;

      .showed {
        display:block;
        margin:26px 26px 15px;
      }
    }

    /* Give auth room */
    .mobile-nav-auth {
      display:none;
      margin-top: 15px;
      margin-bottom: 26px;
      font-family: $bold-text;

      a.btn {
        margin-left:26px;

        &.eda-header-button-primary {
          border-radius: 20px;
          border-style: solid;
          border-width: 1px;
          padding-left: 24px;
          padding-right: 24px;
          height: 42px;
          padding-top: 8px;

          color: $white;
          background-color: $pink-EDA;
          border: 2px solid $pink-EDA;

          &:hover, &:focus {
            color: $pink-EDA;
            background-color: $white;
            border: 2px solid $pink-EDA;
          }
        }

        &.eda-header-button-primary-inverse {
          border-radius: 20px;
          border-style: solid;
          border-width: 1px;
          padding-left: 24px;
          padding-right: 24px;
          height: 42px;
          padding-top: 8px;

          color: $pink-EDA;
          background-color: $white;
          border: 2px solid $pink-EDA;

          &:hover, &:focus {
            color: $white;
            background-color: $pink-EDA;
            border: 2px solid $pink-EDA;
          }
        }
      }
    }

    .account-logged-in {
      display:none;
    }

    /* Headers */
    .mobile-header {
      font-size: 16px;
      margin-left: 26px;
      /* margin-bottom: 18px; */
      /* color: lighten($black, 53.5%); */
    }

    /* Unstyle all lists */
    ul {
      padding-left:0;
      list-style: none;

      li {
        padding: 20px 0;
      }

      /* Media stylings within list */

      &.loggedin-list {
        /*
        border-bottom: 1px solid #888888;
        margin-bottom: 26px;
        */
        padding-bottom: 26px;

        a {
          color: $white;
          text-decoration: none;
          height: 100%;
          display: inline-block;

          &:hover, &:focus {
            background: darken($gray-dark, 5%);
          }
        }

        .media {
          cursor: pointer;
          padding: .8rem 0;
        }

        .media-left {
          vertical-align: middle;
        }

        i.media-object, icon.media-object {
          /* font-size: 28px; */
          font-size: 10px;
        }

        .media-body {
          /* top: 3px; */
          position: relative;
          padding-left: 6px;
        }
      }

      /* Media stylings within list */

      &.mobile-service-list {
        border-top: 1px solid #BBC7CE;

        .media {
          /* padding: .8rem 0; */
          /* padding: .7rem 0; */
          /* cursor: pointer; */
          padding: 0;
        }

        a.link-header-mobile {
          color: unset;
          height: 100%;
          width: 100%;
          display: inline-block;
          padding: .7rem 0;
          cursor: pointer;

          &:hover, &:focus {
            background-color: darken($gray-dark, 5%);
          }
        }

        .media-left {
          /* vertical-align: middle; */
          display: inline-block;
          vertical-align: top;
          padding-top: 5px;
          padding-right: 7px;
        }

        i.media-object, icon.media-object {
          /* font-size: 28px; */
          font-size: 10px;
        }

        .media-body {
          /* top: 3px; */
          position: relative;
          padding-left: 6px;
          display: inline-block;
          width: unset;
        }
      }

      &.brochure-list {
        border-top: 1px solid #888888;

        li {
          font-size: 18px;
          font-family: $bold-text;
          text-transform: uppercase;
          margin-top:26px;
        }

        a {
          // color: $white;
          // font-family: $regular-text;
          text-decoration: none;

          &:hover, &:focus {
            font-family: $bold-text;
            text-decoration: underline;
          }
        }
      }
    }

    &.signed-out {
      .mobile-nav-auth {
        display:block;
      }

      .account-logged-in {
        display:none;
      }
    }

    &.signed-in {
      .account-logged-in {
        display: block;
        margin-top: 20px;
      }
    }
  }
}


/*
=============================================================================
Subheader
=============================================================================
*/

.subheader {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-justify-content: center; /* Safari 6.1+ */
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 136px;
  left: 0;
  right: 0;
  height: $subheader-height;
  background: rgba($white, 0.1);
  color: $white;
  font-size: 18px;
  font-family: $bold-text;
  z-index: 100;

  @media (max-width: $screen-sm) {
    top: 62px;
  }
}

.subheader-service {
  /* font-size: 22px; */
  background-color: $background-tertiary;
  color: $color-font;
  font-size: 26px;
  padding-top: 0px;

  @media (max-width: $screen-sm) {
    font-size: 18px;
    padding-top: 0px;
  }
}

.ed-header{

  background-color: $midnight;
  font-size: 22px;
  text-align: center;

  @media (max-width: $screen-sm) {
    font-size: 18px;
    padding: 0px 10px;
  }

  &.ed-header-cialis{
    background-color: $red;
  }

  &.ed-header-cold-sore{
    background-color: $midnight;
  }

  &.ed-header-sand-orange {
    background-color: $background-tertiary;
    color: $color-font;
  }
}

.wellness-header{
  background-color: #F15F5E;
  font-size: 22px;
  text-align: center;

  @media (max-width: $screen-sm) {
    font-size: 18px;
    padding: 0px 10px;
    height: 35px;
  }

  &.ed-header-cialis{
    background-color: $red;
  }

  &.ed-header-cold-sore{
    background-color: $midnight;
  }
}


#desktop-menu-faq.hide-general {
  display: none;
}

#mobile-menu-faq.hide-general {
  display: none;
}

#mobile-header-faq-EDA {
  display: none;

  &.hide-eda {
    display: none;
  }

  @media(max-width: $screen-sm - 1) {
    display: block;
  }

  @media(max-width: 333px) {
    display: none;
  }
}

#mobile-sildenafil-delivery-faq {
  display: none;

  &.hide-eda {
    display: none;
  }

  @media(max-width: $screen-sm - 1) {
    display: block;
  }
}

#desktop-sildenafil-delivery-faq {
  display: block;

  &.hide-eda {
    display: none;
  }

  @media(max-width: $screen-sm - 1) {
    display: none;
  }
}


#lemonaid-icon-general.hide-general {
  display: none;
}

#lemonaid-logo-general.hide-general {
  display: none;
}

#lemonaid-logo-eda {
  display: block;

  &.hide-eda {
    display: none;
  }

  @media(min-width: $screen-sm + 1) and (max-width: 1015px) {
    padding-top: 15px;
    padding-right: 15px;
  }

  @media(max-width: $screen-sm) {
    padding-top: 13px;
  }
}

.container-menu{
  display: flex;
  justify-content: space-between;
  max-height: 400px;
  padding: 0 40px;

  .service-menu{
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    padding-top: 60px;
    width: 55%;

    @media (max-width: $screen-laptop) {
      width: 75%;
    }

    .category-wrapper{
      display: flex;
      flex-direction: column;
      width:100%;
      height: 100%;

      .category-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-height: 340px;
      }

      .category-item {
        display: flex;
        align-items: center;
      }

      a {
        font-size: 16px;
        color: $color-font;
      }
    }

    .category-title{
      font-family: "Freizeit Lemonaid","Helvetica Neue",Helvetica,Arial,sans-serif;
      color: $color-secondary;
      font-size: 20px;
      font-weight: 500;
    }
  }
}

.cards-menu {

  &__popular-section {
    &__cards {

      .img-container {
        width: 316px;
        height: 458px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $background-primary;
        padding: 30px 16px 54px;

        &__title {
          text-align: center;
          margin: 0 0 20px;
        }

        &__logo {
          width: 202px;
          height: 227px;
          margin-bottom: 38px;
        }

        &__buttons-holder {
          display: flex;
          align-items: center;

          &__apple {
            width: 113px;
            margin-right: 16px;
          }

          &__android {
            width: 125px;
          }
        }
      }

      &:hover a {
        text-decoration: none;
      };

      .cards-item {
        position: relative;
        width: 301px;
        height: auto;
        background: $white;
        box-shadow: 0 38px 128px rgba(11, 88, 171, 0.05), 0 13.8706px 46.7221px rgba(11, 88, 171, 0.0344991), 0 6.73393px 22.6827px rgba(11, 88, 171, 0.0278145), 0 3.3011px 11.1195px rgba(11, 88, 171, 0.0221855), 0 1.30526px 4.39666px rgba(11, 88, 171, 0.0155009);
        border-radius: 10px;
        margin-bottom: 20px;
        cursor: pointer;

        &__img {
          width: 100%;
          height: 150px;
          object-fit: cover;
          border-radius: 10px 10px 0 0;

          @media (max-width: $screen-laptop) {
            height: 120px;
          }

          @media (max-width: $screen-sm) {
            height: 139px;
            margin-bottom: 12px;
            width: 100%;
          }
        }

        &__img-meditation {
          position: absolute;
          top: 75px;
          right: 10px;
          height: 100px;

          @media (max-width: $screen-laptop) {
            height: 90px;
          }
        }

        &__content {
          padding: 15px 0 10px 15px;

          &__subtitle {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 150%;
            margin: 0;
            color: $color-font;

            .icon-ic-arrow-right {
              margin-left: 10px;

              &:before {
                content: '\e908';
                font-family: $font-icons-rebrand;
              }
            }
          }
        }
      }
    }
  }
}

%category-status {
  height: 14px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-left: 5px;
  border-radius: 6px;
  font-size: 10px;
}

.category-popular {
  @extend %category-status;

  background-color: #FFF731;
}

.category-new {
  @extend %category-status;

  background-color: #00A676;
  color: $white;
}

.mobile-menu {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 100px;
  }
}

.mobile-menu-item {
  border-top: 1px solid #BBC7CE;

  a {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: $color-font;
    font-family: 'Atkinson Hyperlegible','Helvetica Neue',Helvetica,Arial,sans-serif;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .category-wrapper {
    padding: 15px 9px 5px;
    display: flex;
    flex-direction: column;

    .category-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    a {
      display: inline-flex;
      justify-content: space-between;
      padding: 10px 0;
    }
  }

  .mobile-service-list {
    border-top: 1px solid #BBC7CE;
    max-height: 440px;
    overflow-y: auto;
    display: block;
    opacity: 1;
    transition: all 0.5s ease;

    &.hidden-service {
      max-height: 0;
      opacity: 0;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #E9F2E6;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-primary;
      border-radius: 6px;
    }
  }

  .category-title{
    color: #BBC7CE;
  }
}

.dropdown-menu {
  display: block;
  max-height: 0;
  opacity: 0;
  transition: all .5s ease;
}

.open {
  > .dropdown-menu {
    opacity: 1;
    max-height: 500px;
    border-top: 1px solid $border-opaque;
  }
  .navbar-header__link {
    color: $color-primary;
  }
}

.mobile-call-us {
  text-align: center;
  vertical-align: bottom;
  padding-bottom: 10px;

  p {
    margin-bottom: 10px;
  }
}

.hidden-service {
  display: none;
}

.open .caret {
  transform: rotate(180deg);
}

.navbar-header__desktop.logo-phone {
  grid-template-columns: 1fr;
}
