.footer {
  padding: 60px 120px;
  display: flex;
  margin-top: 40px;

  @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
    padding: 30px 60px;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-xlg) {
    padding: 30px 60px;
  }

  @media (max-width: $screen-sm - 1) {
    padding: 30px 60px;
    /*
    flex-direction: column-reverse;
    padding: 45px 0 30px;
    width: 100%;
    */

    display: block;
  }

  &__title {
    font-family: "Atkinson Hyperlegible", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: $color-font;
    text-transform: uppercase;
    font-size: 12px;
  }

  &__content {
    width: 60%;
    display: inline-block;

    @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
      width: 70%;
    }

    @media (min-width: $screen-sm) and (max-width: $screen-xlg) {
      width: 50%;
    }

    @media (max-width: $screen-sm - 1) {
      width: 100%;
    }
  }

  &__copyright {
    width: 40%;
    display: inline-block;
    vertical-align: top;

    @media (min-width: ($screen-xlg + 1)) and (max-width: $screen-laptop) {
      width: 30%;
    }

    @media (min-width: $screen-sm) and (max-width: $screen-xlg) {
      width: 50%;
    }

    @media (max-width: $screen-sm - 1) {
      width: 100%;
      /*
      max-width: 300px;
      display: flex;
      flex-direction: column;
      margin-right: 0;
      padding: 30px 40px 0;
      */

      p, h5 {
        order: 1;
      }
    }

    &__logo {
      position: relative;
      top: -30px;
      height: 105px;
      display: none;

      @media (min-width: ($screen-xlg + 1)) {
        display: block;
        left: -35px;
      }

      &.tablet {
        display: none;

        @media (max-width: $screen-sm - 1) {
          display: block;
          margin: auto;
        }

        @media (min-width: $screen-sm) and (max-width: $screen-xlg) {
          display: block;
          margin: auto;
        }
      }

      &-cqc {
        display: block;
        position: relative;
        top: -30px;
        width: 100px;
        height: 30px;

        @media (max-width: $screen-sm - 1) {
          margin: auto;
        }

        @media (min-width: $screen-sm) and (max-width: $screen-xlg) {
          margin: auto;
        }
      }

      @media (max-width: $screen-sm - 1) {
        display: none;
      }
    }

    &__images-holder {
      display: flex;
      align-items: center;
      margin-top: 30px;

      @media (max-width: $screen-sm - 1) {
        flex-direction: column;
        order: -1;
        margin: 0 0 60px;
      }

      .lemonaid-legit {
        width: 104px;
        margin-right: 25px;

        @media (max-width: $screen-sm - 1) {
          margin: 0 0 30px;
        }
      }
    }

    p, a {
      font-size: 14px;
      line-height: 150%;
    }
  }

  &__services {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    display: none;


    @media (min-width: ($screen-xlg + 1)) {
      display: flex;
      max-width: 600px;
      justify-content: space-between;
    }

    @media (max-width: $screen-sm - 1) {
      text-align: center;
      display: block;
    }

    &.tablet {
      display: none;

      @media (min-width: $screen-sm) and (max-width: $screen-xlg) {
        display: block;
        margin-top: 15px;

        .healthaid-link {
          margin-left: 45px;
        }

        .privacy-policy-link {
          margin-left: 45px;
        }
      }
    }

    &__info {
      margin-top: 30px;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.01em;

      @media (max-width: $screen-sm - 1) {
        width: 100%;
        padding: 0;
        text-align: center;
      }
    }

    &__block {
      margin-bottom: $space-lg;
      line-height: 24.5px;
    }

    &__title {
      margin-bottom: 45px;
      margin-top: 0;
    }

    a {
      cursor: pointer;
      color: $color-link;
      line-height: 150%;
      font-family: $link-text;
      font-size: 14px;

      @media (max-width: $screen-sm - 1) {
        display: block;
        padding-top: 15px;
      }

      &:hover, &:focus {
        color: $color-link;
        text-decoration: underline;
      }
    }
  }

  &__mobile-services {
    display: none;

    @media (max-width: $screen-sm - 1) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__mobile-logo {
      display: block;
      width: 150px;
      margin-bottom: 45px;
    }

    &__block {
      width: 100%;
      border-bottom: 1px solid $border-opaque;

      &.open {
        .footer__mobile-services__header__icon {
          transform: rotate(180deg);
        }

        .footer__mobile-services__list {
          display: block;
        }
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 45px;

      h3 {
        margin: 0;
      }

      &__icon {
        &:before {
          content: '\e903';
          font-family: $font-icons-rebrand;
        }
      }
    }

    &__list {
      display: none;
      list-style: none;
      padding: 0 0 20px 45px;

      li a {
        font-size: 14px;
        color: $color-font;
      }
    }
  }
}
